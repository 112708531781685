(function($) {

    var CartPopup = function(element, options) {

        this.element = element;
        this.defaults = {animationDuration: 300};

        this.options = $.extend({}, this.defaults, options);

        this.image = element.find('[data-purpose="product-image"]');
        this.name = element.find('[data-purpose="product-name"]');
        this.price = element.find('[data-purpose="product-price"]');
        this.quantity = element.find('[data-purpose="product-quantity"]');
        this.product_total = element.find('[data-purpose="product-total"]');
        this.cart_total = element.find('[data-purpose="cart-subtotal"]');
        this.dismiss_button = element.find('[data-purpose="dismiss-button"]');
        
        this.init();

    };

    CartPopup.prototype = {

        init: function() {
            var _self = this;

            $(document).on('shoppingstreet.addedtocart', function(e, eventData) {
                if (_self.element.hasClass('opened')) _self.close();
                setTimeout(function() {
                    if (_self.image.length && eventData.variation.images.length) _self.image.attr('src', eventData.variation.images[0].url);
                    if (_self.name.length) _self.name.html(eventData.variation.name);
                    if (_self.price.length) _self.price.html(eventData.variation.price);
                    if (_self.quantity.length) _self.quantity.html(eventData.quantity + ' ' + eventData.product.unit);
                    if (_self.product_total.length) _self.product_total.html(eventData.cart.item_total);
                    if (_self.cart_total.length) _self.cart_total.html(eventData.cart.subtotal);

                    _self.open();

                }, _self.options.animationDuration);
            });

            if (_self.dismiss_button.length) {
                _self.dismiss_button.click(function() {
                    _self.close();
                    return false;
                });
            }
        },

        open: function() {
            var _self = this;

            var other = $('.system-overlay.opened');
            if (other.length) {
                other.removeClass('opened');
                setTimeout(function() {
                    _self.element.addClass('opened');
                }, _self.options.animationDuration);
            }
            else {
                _self.element.addClass('opened');
            }
        },

        close: function() {
            var _self = this;
            _self.element.removeClass('opened');
        }

    };

    $.fn.cartPopup = function(options) {
        return this.each(function() {
            new CartPopup($(this), options);
        });
    };
})(jQuery);
