(function(window){
    function QuantityNoticeForm(form, params) {
        try {
            if (!form) {
                throw 'Form is required!';
            }

            this.form = form;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    QuantityNoticeForm.prototype = {
        variationId: undefined,
        sendedForm: false,
        form: undefined,
        button: undefined,
        options: {
            remodalId: 'product-notice-remodal'
        },
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.button = this.form.find('[data-purpose="notice-form-button"]');
        },
        addDOMEvents: function() {
            var _self = this;

            $(document).on('shoppingstreet.open_product_notice_remodal', function(e, variationId){
                _self.variationId = variationId;
                openRemodalContent(_self.getOption('remodalId'));
            });

            if (this.button && this.button.length > 0) {
                this.button.click(function(e) {
                    e.preventDefault();
                    _self.sendForm();
                });
            }
        },
        setParams: function(params) {

        },
        getOption: function(key) {
            return typeof this.options[key] !== 'undefined' ? this.options[key] : undefined;
        },
        sendForm: function() {
            if (!this.sendedForm) {
                var _self = this;
                this.sendedForm = true;
                var data = {};
                $.each(this.form.serializeArray(), function(index, item) {
                    data[item.name] = item.value;
                });

                data.variation_id = this.variationId;
                if (typeof current_lang !== 'undefined') {
                    data.lang = current_lang;
                }
                $.ajax({
                    url:'/profilcontroller/saveProductNotificationRequest',
                    dataType:'json',
                    type:'post',
                    data: data,
                    success: function(resp) {
                        if (typeof resp.success !== 'undefined' && resp.success) {
                            _self.clearForm();
                            if (typeof resp.message != 'undefined') {
                                systemMessenger.showMessage(resp.message, 'info');
                            }
                            if (typeof resp.referenceId !== 'undefined' && resp.referenceId !== null) {
                                if (typeof googleTracker !== 'undefined') {
                                    googleTracker.trackEvent('product_notification', 'Lead', 'Product notification', resp.referenceId);
                                } else if (typeof ga === 'function') {
                                    ga('send', 'event', 'Lead', 'Product notification', resp.referenceId);
                                }
                            }
                        } else {
                            if (typeof resp.errors != 'undefined' && Object.keys(resp.errors).length > 0) {
                                var showedMessage = [];
                                $.each(Object.keys(resp.errors), function (val, key) {
                                    var item = _self.form.find('[name="' + key + '"]');
                                    var msg = resp.errors[key];
                                    if (msg.trim() !== '' && showedMessage.indexOf(msg) < 0) {
                                        showedMessage.push(msg);
                                        systemMessenger.showErrorMessage(resp.errors[key]);
                                    }
                                    
                                    if (item.length > 0) {
                                        if (typeof inputValidator != 'undefined') {
                                            inputValidator.removeFeedbackClasses(item[0]);
                                            inputValidator.setFeedback(item[0], false);
                                        } else {
                                            if (item.data('feedback-target') == 'label') {
                                                _self.form.find('label[for="' + (item.attr('id')) + '"]').addClass(_self.options.error_class);
                                            } else if (item.data('feedback-target') == 'parent') {
                                                item.parent().addClass(_self.options.error_class);
                                            } else {
                                                item.addClass(_self.options.error_class);
                                            }
                                        }
                                    }
                                });
                            }    
                        }
                        // Ez az esemény a gyerekruhában került be, célja, hogy egyedi class-t teszünk clickre, amit ilyenkor leveszünk.
                        $(document).trigger("shoppingstreet.removebuttonresponse", _self.button);
                    },
                    complete: function(){
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 1000);
                    }
                });
            }
        },
        clearForm: function() {
            this.form.find('input[type="text"]').val('');
            this.form.find('input[type="checkbox"]').prop('checked', false);
            $('[data-remodal-id="'+this.getOption('remodalId')+'"]').remodal().close();
        }
    };

    window.QuantityNoticeForm = QuantityNoticeForm;

})(window);
