var WebshopUtils = function(params) {

  this.defaults = {
    numberFormat: {
      dec_point: '.',
      th_point: ',',
      sign_pos: 'after',
      has_decimals: false,
      sign: 'Ft',
      round_up: false,
      round_to: 5
    }, 
    recaptcha: {
      use: false,
      siteKey: ''
    }
  };

  this.options = typeof Object.assign != 'undefined'
    ? Object.assign({}, this.defaults, params)
    : params;
  // this.params = params;
  this.init();

};

WebshopUtils.prototype = {
  options: {
    numberFormat: {
      dec_point: '.',
      th_point: ',',
      sign_pos: 'after',
      has_decimals: false,
      sign: 'Ft',
      round_up: false,
      round_to: 5
    },
    recaptcha: {
      use: false,
      siteKey: ''
    },
    time_unit: 'minute'
  },
  init: function() {},
  formatPrice: function(value, formatted_only, with_sign) {
    var _self = this;
    var numberFormat = _self.options.numberFormat;
    
    if (!with_sign && with_sign !== false) {
      with_sign = true;
    }

    var result = _self.numberFormat(parseFloat(value));

    if (numberFormat.sign_pos == 'after') {
      result.formatted = result.formatted + (with_sign ? ' ' + numberFormat.sign : '');
    } else {
      result.formatted = (with_sign ? numberFormat.sign + ' ' : '') + result.formatted;
    }

    return formatted_only
      ? result.formatted
      : result;
  },

  numberFormat: function(number, formatted_only, numberFormat) {
    var _self = this;

    numberFormat = typeof Object.assign != 'undefined'
      ? Object.assign({}, _self.options.numberFormat, numberFormat)
      : _self.options.numberFormat;

    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+ number)
        ? 0
        : + number,
      prec = !isFinite(+ 2)
        ? 0
        : Math.abs(2),
      sep = numberFormat.th_point,
      dec = numberFormat.dec_point,
      s = '',
      toFixedFix = function(n, prec) {
        var k = Math.pow(10, prec);
        return '' + (
        Math.round(n * k) / k).toFixed(prec);
      };

    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (
      prec
      ? toFixedFix(n, prec)
      : '' + Math.round(n)).split('.');

    var unformatted = (
      prec
      ? toFixedFix(n, prec)
      : '' + Math.round(n));
    var formatted = s;

    if (formatted[0].length > 3) {
      formatted[0] = formatted[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }

    if ((formatted[1] || '').length < prec) {
      formatted[1] = formatted[1] || '';
      formatted[1] += new Array(prec - formatted[1].length + 1).join('0');
    }

    formatted = numberFormat.has_decimals
      ? formatted.join(dec)
      : formatted[0];

    return formatted_only
      ? formatted
      : {
        'unformatted': unformatted,
        'formatted': formatted
      };
  },
  roundNumber: function(number) {
    var format = this.options.numberFormat;
    if (format.round_to > 0) {
      var num = number / parseFloat(format.round_to);
      var rounded = format.round_up
        ? Math.ceil(num)
        : Math.round(num);

      return rounded * format.round_to;
    }
    return number;
  },
  addToCart: function(variation_id, quantity, variationData, productData) {
    if (!variation_id)
      return;
    var _self = this;

    if (!quantity || quantity < 0)
      quantity = 1;

    $.ajax({
      url: cart_controller + '/add_to_cart',
      dataType: 'json',
      type: 'post',
      data: {
        variation_id: variation_id,
        quantity: quantity
      },
      success: function(ret) {
        if (ret.success) {
          var data = {
            cart: {
              subtotal: ret.subtotal,
              item_total: ret.item_total,
              cart_count: ret.cart_count
            },
            product: productData ? productData : ret.item,
            variation: variationData ? variationData : ret.item,
            quantity: quantity
          };

          if (ret.item) {
            if (typeof googleTracker != 'undefined') {
              googleTracker.addEcommerceProductAddcart({
                id: ret.item.reference_id,
                name: ret.item.name,
                category: ret.item.category,
                variant: ret.item.variant,
                price: ret.item.item_price,
                quantity: ret.item.quantity
              });
            } else if (typeof ga != 'undefined') {
              ga('ec:addProduct', {
                id: ret.item.reference_id,
                name: ret.item.name,
                category: ret.item.category,
                variant: ret.item.variant,
                price: ret.item.item_price,
                quantity: ret.item.quantity
              });

              ga('ec:setAction', 'add');
              ga('send', 'event', 'Cart Actions', 'Add to cart', ret.item.reference_id, {
                // hitCallback: function() {

                // }
              });
            }
            if (typeof window.fbq != 'undefined') {
              fbq('track', 'AddToCart', {
                content_ids: [ret.item.reference_id],
                content_type: 'product',
                value: ret.item.item_price,
                currency: typeof window.googleTracker !== 'undefined' ? googleTracker.getCurrency() : 'HUF'
              });
            }
          }

          if (ret.remarketing && typeof googleTracker !== 'undefined') {
            googleTracker.trackRemarketingCart(ret.remarketing.items, ret.remarketing.total_value);
          }
          
          $(document).trigger('shoppingstreet.addedtocart', data);
          $(document).trigger('shoppingstreet.cartcountchanged', ret.cart_count);
          $(document).trigger('shoppingstreet.couponboxevent', ret);

          _self.refreshSubtotals(ret);
        } else {
          if (typeof systemMessenger != undefined) {
            systemMessenger.showErrorMessage(ret.message);
          } else {
            $.systemMessage(ret.message, 'error');
          }
        }
      }
    });
  },
  removeFromCart: function(item, callbackFn) {
    var _self = this;

    var par = item.closest('[data-item-id]');
    var id = par.data('item-id');

    $.post(cart_controller + '/delete_item', {
      variation_id: id
    }, function(ret) {
      if (ret.success) {

        _self.refreshSubtotals(ret);

        if (ret.item) {
          if (typeof googleTracker != 'undefined') {
            googleTracker.addEcommerceProductRemovecart({
              id: ret.item.reference_id,
              name: ret.item.name,
              category: ret.item.category,
              variant: ret.item.variant,
              price: ret.item.item_price,
              quantity: ret.item.quantity
            });
          } else if (typeof ga != 'undefined') {
            ga('ec:addProduct', {
              id: ret.item.reference_id,
              name: ret.item.name,
              category: ret.item.category,
              variant: ret.item.variant,
              price: ret.item.item_price,
              quantity: ret.item.quantity
            });

            ga('ec:setAction', 'remove');
            ga('send', 'event', 'Cart Actions', 'Remove from cart', ret.item.reference_id);
          }
        }

        if (ret.cart_count) {
          if (typeof callbackFn == 'function') {
            callbackFn.call();
          }
          $(document).trigger('shoppingstreet.cartcountchanged', ret.cart_count);
        } else {
          window.location.reload();
        }

        if (ret.remarketing && typeof googleTracker !== 'undefined') {
          googleTracker.trackRemarketingCart(ret.remarketing.items, ret.remarketing.total_value);
        }

        $(document).trigger('shoppingstreet.couponboxevent', ret);
      } else {
        if (typeof systemMessenger != 'undefined') {
          systemMessenger.showErrorMessage(ret.message);
        } else {
          $.systemMessage(ret.message, 'error');
        }
      }
    }, 'json');
  },
  setCartProductQuantity: function(item, callbackFn) {
    var _self = this;

    var id = item.data('item-id');
    var par = item.closest('[data-item-id]');
    var price_field = par.find('[data-purpose="price"]');
    var unit_price_field = par.find('[data-purpose="unit-price"]');
    
    $.post(cart_controller + '/set_count', {
      variation_id: par.data('item-id'),
      quantity: item.val()
    }, function(ret) {
      if (ret.success) {

        _self.refreshSubtotals(ret);

        if (ret.item) {
          if (!item.val()) {
            if (typeof googleTracker != 'undefined') {
              googleTracker.addEcommerceProductRemovecart({
                id: ret.item.reference_id,
                name: ret.item.name,
                category: ret.item.category,
                variant: ret.item.variant,
                price: ret.item.item_price,
                quantity: ret.item.quantity
              });
            } else if(typeof ga != 'undefined') {
              ga('ec:addProduct', {
                id: ret.item.reference_id,
                name: ret.item.name,
                category: ret.item.category,
                variant: ret.item.variant,
                price: ret.item.item_price,
                quantity: ret.item.quantity
              });

              ga('ec:setAction', 'remove');
              ga('send', 'event', 'Cart Actions', 'Remove from cart', ret.item.reference_id);

            }
          }
        }

        if (ret.remarketing && typeof googleTracker !== 'undefined') {
          googleTracker.trackRemarketingCart(ret.remarketing.items, ret.remarketing.total_value);
        }

        if (!item.val()) {
          if (typeof callbackFn === 'function') {
            callbackFn.call();
          }
        } else { 
          if(price_field.length) {
            price_field.html(ret.item_total);
          }

          if (typeof ret.unit_price !== 'undefined' && unit_price_field.length > 0) {
            unit_price_field.html(ret.unit_price);
          }
        }

        if (ret.cart_count) {
          $(document).trigger('shoppingstreet.cartcountchanged', ret.cart_count);
        } else {
          window.location.reload();
        }

        $(document).trigger('shoppingstreet.couponboxevent', ret);
      } else {
        if (typeof systemMessenger != 'undefined') {
          systemMessenger.showErrorMessage(ret.message);
        } else {
          $.systemMessage(ret.message, 'error');
        }
      }
    }, 'json');

    return false;
  },
  refreshCartSumContent: function(sum) {
    this.refreshPurposeFields('cart-subtotal', sum);
  },
  refreshFreeShippingBorderContent: function(need) {
    var content = $('[data-purpose="free-shipping-border"]');
    if (need > 0) {
      content.parent().removeAttr('style');
      content.html(this.formatPrice(need, true));
    } else {
      var selector = content.data('to-hide');
      var target = selector ? $(selector) : undefined;
      
      if (target && target.length > 0) {
        target.hide();
      } else {
        content.parent().hide();
      }
    }
  },
  refreshPurposeFields: function(key, value, hideIfEmpty) {
    if (key !== undefined, value !== undefined) {

      if (hideIfEmpty == undefined)
        hideIfEmpty = false;

      var fields = $('[data-purpose="' + key + '"]');

      fields.html(value);
      fields.closest('.total-row').removeClass('hidden');

      if (hideIfEmpty && !parseFloat(value)) {
        fields.closest('.total-row').addClass('hidden');
      }
    }
  },
  copyAddress: function() {

    var source = $(document).find('[data-purpose="copy-source"]');
    var dest = $(document).find('[data-purpose="copy-dest"]');

    source.find('input, select, textarea').each(function(ind, el) {

      var inp = $(el);
      var purpose = inp.data('purpose');
      if (purpose != undefined) {
        var other = dest.find('[data-purpose="' + purpose + '"]');
        if (other.length) {
          if (inp.attr('type') == 'radio' || inp.attr('type') == 'checkbox') {
            other.prop('checked', inp.prop('checked'));
          } else {
            other.val(inp.val());
          }
        }
      }
    });
  },
  getCityByZip: function(item) {
    var city = $(item).closest('[data-purpose="address-container"]').find('[data-purpose="city"]');
    if (city.length) {
      var zip_code = $(item).val();

      if (zip_code) {
        $.get(ajax_controller + '/get_city_by_zip/' + zip_code, null, function(ret) {
          if (ret && ret.helyiseg) {
            city.val(ret.helyiseg);
            $(document).trigger('shoppingstreet.shippingaddresschanged');
          }

        }, 'json');
      }
    }
  },
  refreshSubtotals: function(ret) {
    var _self = this;

    _self.refreshPurposeFields('cart-subtotal', ret.subtotal);
    _self.refreshPurposeFields('cart-total', ret.total);
    _self.refreshPurposeFields('discount', ret.discount, true);
    
    if (typeof ret.weight != 'undefined')
      _self.refreshPurposeFields('products-weight', ret.weight);
    if (typeof ret.shipping_fee != 'undefined')
      _self.refreshPurposeFields('shipping-fee', ret.shipping_fee, true);
    if (typeof ret.payment_surcharge != 'undefined')
      _self.refreshPurposeFields('payment-surcharge', ret.payment_surcharge, true);

    if (typeof ret.to_free_shipping != 'undefined') {
      _self.refreshFreeShippingBorderContent(ret.to_free_shipping);
    }

    if (typeof ret.subtotal_with_discount != 'undefined') {
      _self.refreshPurposeFields('subtotal-with-discount', ret.subtotal_with_discount);
    }

    if (typeof ret.cart_count != 'undefined') {
      _self.refreshPurposeFields('cart-count', ret.cart_count);
    }

    if (typeof ret.base_currency_total !== 'undefined') {
      _self.refreshPurposeFields('base-currency-total', ret.base_currency_total);
    }

    if (typeof ret.tax_amount !== 'undefined') {
      _self.refreshPurposeFields('order-tax-amount', ret.tax_amount);
    }

    if (typeof ret.net_subtotal !== 'undefined') {
      _self.refreshPurposeFields('cart-net-subtotal', ret.net_subtotal);
    }
  },
  formatTimeString: function(time, only_hour, hour_txt, minute_txt) {
    var result = '';
    var hour = null;
    var minute = null;

    unit = undefined;
    if (typeof this.options.time_unit != 'undefined') {
      unit = this.options.time_unit;
    }
    
    switch (unit) {
      case 'hour':
        hour = Math.floor(time / 1);
        minute = Math.ceil((time - hour) * 60);
        break;
      default:
        hour = Math.floor(time / 60);
        minute = Math.ceil(time % 60);
        break;
    }

    if (hour != null && hour >= 0) {
      result += hour + ' ' + hour_txt;
    }
    
    if (!only_hour && minute != null && minute > 0) {
      result += (result != '' ? ' ' : '') + minute + ' ' + minute_txt;
    }

    return result;
  },
  getOption: function(key) {
    return (typeof this.options[key] != 'undefined') ? this.options[key] : null;
  },
  getCurrency: function() {
    var numberFormat = this.options.numberFormat;
    return typeof numberFormat.sign !== 'undefined' ? numberFormat.sign : '';
  },
  hasRecaptcha: function() {
    return this.options.recaptcha.use;
  },
  getRecaptchaSiteKey: function() {
    return this.options.recaptcha.siteKey;
  }

};
